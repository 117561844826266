<template>
  <div class="rencai_c">
    <div class="rencai_ctopbox">
      <div class="rencai_ctopboximg">
        <img :src="user.cover_img != '0' ? user.cover_img : img" alt="" />
        <div class="rencai_ctopboximgbtn" v-if="userinfo != null">
          <div
            v-if="userinfo.groupid == 2"
            @click="fabulink(user.userid, user.username)"
          >
            直接雇佣
          </div>
          <div
            v-if="user.award != null && userinfo.groupid == 2"
            @click="ck_jl(user.userid)"
          >
            查看简历
          </div>
        </div>
      </div>
      <div class="rencai_ctopboxall">
        <div class="rencai_ctopboxall_l">
          <div class="rencai_ctopboxall_ltop">
            <div class="rencai_ctopboxall_ltop_l">
              <div class="rencai_ctopboxall_ltop_ltop">
                <div class="rencai_ctopboxall_ltop_limg">
                  <img :src="user.userpic" :alt="user.username" />
                </div>
                <div class="rencai_ctopboxall_ltop_lname">
                  <p>{{ user.username }}</p>
                  <p>{{ user.major == null ? "暂无" : user.major }}</p>
                </div>
                <input
                  v-if="guanzhutype"
                  class="rencai_ctopboxall_ltop_lgz"
                  @click="guanzhu(user.userid)"
                  type="button"
                  style="border: none"
                  :value="gz == '0' ? '关注' : '已关注'"
                  :class="gz == '0' ? '' : 'rencai_ctopboxall_ltop_lgz_000'"
                />
              </div>
              <ul class="rencai_ctopboxall_ltop_lbm" v-if="skillone != ''">
                <li v-for="(item, index) in userskill" :key="index">
                  {{ user_info_iostanbqall[(item-1)].text }}
                </li>
              </ul>
            </div>
            <div class="rencai_ctopboxall_ltop_r">
              <div class="rencai_ctopboxall_ltop_rli">
                <p>{{ user.photo_num }}</p>
                <p>作品<span></span></p>
              </div>
              <div class="rencai_ctopboxall_ltop_rli">
                <p>{{ fsnum }}</p>
                <p>粉丝</p>
              </div>
            </div>
          </div>
          <div class="rencai_ctopboxall_lbottom">
            <div class="rencai_ctopboxall_lbottomtext">
              <div class="rencai_ctopboxall_lbottomtexttitle">个人简介</div>
              <div class="rencai_ctopboxall_lbottomtexttext">
                {{ user.saytext }}
              </div>
              <div class="rencai_ctopboxall_lbottomtexttext_d" v-show="more1">
                {{ user.saytext }}
              </div>
              <div class="rencai_ctopboxall_lbottomtextbtn" @click="more(1)">
                详情
              </div>
            </div>
            <div class="rencai_ctopboxall_lbottomtext">
              <div class="rencai_ctopboxall_lbottomtexttitle">获奖情况</div>
              <div class="rencai_ctopboxall_lbottomtexttext">
                {{ user.award == null ? "暂无" : user.award }}
              </div>
              <div class="rencai_ctopboxall_lbottomtexttext_d" v-show="more2">
                {{ user.award == null ? "暂无" : user.award }}
              </div>
              <div class="rencai_ctopboxall_lbottomtextbtn" @click="more(2)">
                详情
              </div>
            </div>
            <div class="rencai_ctopboxall_lbottomtext">
              <div class="rencai_ctopboxall_lbottomtexttitle">作品采用</div>
              <div class="rencai_ctopboxall_lbottomtexttext">暂无</div>
              <div class="rencai_ctopboxall_lbottomtexttext_d" v-show="more3">
                暂无
              </div>
              <div class="rencai_ctopboxall_lbottomtextbtn" @click="more(3)">
                详情
              </div>
            </div>
          </div>
          <ul class="rencai_ctopboxall_lbottom_ios">
            <li>
              <icon-svg icon-class="iconresume" />
              <span>个人简介</span>
              <icon-svg icon-class="iconyou" />
              <div class="rencai_ctopboxall_lbottom_iostanchu">
                <van-cell is-link @click="saytextios = true"></van-cell>
              </div>
            </li>
            <li>
              <icon-svg icon-class="iconhuojiangrenyuan" />
              <span>获奖情况</span>
              <icon-svg icon-class="iconyou" />
              <div class="rencai_ctopboxall_lbottom_iostanchu">
                <van-cell @click="winningios = true"></van-cell>
              </div>
            </li>
            <li>
              <icon-svg icon-class="iconwork2" />
              <span>作品采用情况</span>
              <icon-svg icon-class="iconyou" />
              <div class="rencai_ctopboxall_lbottom_iostanchu">
                <van-cell @click="worksios = true"></van-cell>
              </div>
            </li>
          </ul>
        </div>
        <div class="rencai_ctopboxall_r"><div id="myChart"></div></div>
        <div class="rencai_ctopboxall_r rencai_ctopboxall_rios">
          <div id="myChartios"></div>
        </div>
      </div>
    </div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane
        v-for="(iii, index) in eltab"
        :key="index"
        :label="iii.label"
        :name="iii.name"
      >
        <ul class="rencai_cul">
          <li
            class="rencaiulboxli_rightli"
            v-for="item in rencaiulboxli_rightli"
            :key="item.id"
            @click="photo_list(item.id)"
          >
            <img :src="item.titlepic" alt="" />
            <p>{{ item.title }}</p>
          </li>
          <li class="lizero" v-if="lizero">
            <img
              src="https://task.hozoin.cn/Works/20210426/sj-3936605600.png"
              alt=""
            />
          </li>
        </ul>
      </el-tab-pane>
    </el-tabs>
    <van-popup position="bottom" :style="stylepro" v-model="saytextios">{{
      user.saytext
    }}</van-popup>
    <van-popup position="bottom" :style="stylepro" v-model="winningios"
      >暂无</van-popup
    >
    <!-- {{ user.winning }} -->
    <van-popup position="bottom" :style="stylepro" v-model="worksios"
      >暂无</van-popup
    >
    <!-- {{ user.works }} -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      stylepro: {
        height: "35%",
      },
      saytextios: false,
      winningios: false,
      worksios: false,
      guanzhutype: false,
      skillone: "",
      userskill: [],
      img: "https://task.hozoin.cn/Works/20210222/sj-3300420995.png",
      activeName: "first",
      user: {},
      rencaiulboxli_rightli: [],
      limit: 10,
      id: "",
      userinfo: {},
      gz: "0",
      more1: false,
      more2: false,
      more3: false,
      lizero: false,
      fsnum: 0,
      eltab: [
        {
          label: "项目",
          name: "first",
        },
        {
          label: "作品",
          name: "two",
        },
        {
          label: "收藏",
          name: "three",
        },
      ],
      user_info_iostanbqall: [
        {
          text: "规划设计",
          num: "1",
          classred: "",
          ft: "0",
        },
        {
          text: "景观设计",
          num: "2",
          classred: "",
          ft: "0",
        },
        {
          text: "建筑设计",
          num: "3",
          classred: "",
          ft: "0",
        },
        {
          text: "室内设计",
          num: "4",
          classred: "",
          ft: "0",
        },
        {
          text: "软装设计",
          num: "5",
          classred: "",
          ft: "0",
        },
        {
          text: "服装设计",
          num: "6",
          classred: "",
          ft: "0",
        },
        {
          text: "家具设计",
          num: "7",
          classred: "",
          ft: "0",
        },
        {
          text: "交通工具设计",
          num: "8",
          classred: "",
          ft: "0",
        },
        {
          text: "轻工产品设计",
          num: "9",
          classred: "",
          ft: "0",
        },
        {
          text: "机械设备设计",
          num: "10",
          classred: "",
          ft: "0",
        },
        {
          text: "广告设计",
          num: "11",
          classred: "",
          ft: "0",
        },
        {
          text: "包装设计",
          num: "12",
          classred: "",
          ft: "0",
        },
        {
          text: "图书设计",
          num: "13",
          classred: "",
          ft: "0",
        },
        {
          text: "展陈设计",
          num: "14",
          classred: "",
          ft: "0",
        },
        {
          text: "品牌标志设计",
          num: "15",
          classred: "",
          ft: "0",
        },
      ],
    };
  },
  mounted() {
    var that = this;
    var id = that.$route.query.data;
    var user = {};
    this.$axios({
      url: "/api/getTalentDetails",
      method: "post",
      data: {
        userid: id,
      },
    }).then((res) => {
      // console.log(res);
      if (res.data.code == 1) {
        user = res.data.result;
        var width = Number(document.documentElement.clientWidth);
        if (width > 800) {
          var widthpc = Number(document.documentElement.clientWidth / 30);
          var myChart = this.$echarts.init(document.getElementById("myChart"));
          myChart.setOption({
            tooltip: {
              trigger: "axis",
            },
            radar: {
              indicator: [
                { text: "经验", max: 10 },
                { text: "速度", max: 10 },
                { text: "活跃", max: 10 },
                { text: "合作", max: 10 },
                { text: "技能", max: 10 },
                { text: "评价", max: 10 },
              ],
              center: ["50%", "50%"],
              radius: widthpc,
              name: {
                textStyle: {
                  fontSize: 14,
                  color: "#000",
                },
              },
              splitNumber: 3,
              // shape: "circle",
              shape: "polygon",
              axisLine: {
                lineStyle: {
                  color: "rgb(64, 158, 255)", //射线的颜色
                },
              },
              splitArea: {
                show: true,
                areaStyle: {
                  color: "#fff", // 图表背景的颜色
                },
              },
              splitLine: {
                show: true,
                lineStyle: {
                  width: 1,
                  color: "rgb(64, 158, 255)", // 设置网格的颜色
                },
              },
            },
            series: [
              {
                type: "radar",
                tooltip: {
                  trigger: "item",
                },
                color: "rgb(64, 158, 255)",
                itemStyle: { normal: { areaStyle: { type: "default" } } },
                data: [
                  {
                    value: [
                      user.res_exper,
                      user.res_speed,
                      user.res_active,
                      user.res_cooper,
                      user.res_skill,
                      user.res_evaluate,
                    ],
                    name: "个人资料图",
                    name: "个人资料图",
                  },
                ],
              },
            ],
          });
        } else {
          var myChartios = this.$echarts.init(
            document.getElementById("myChartios")
          );
          var widthios = Number(document.documentElement.clientWidth / 15);
          myChartios.setOption({
            tooltip: {
              trigger: "axis",
            },
            radar: {
              indicator: [
                { text: "经验", max: 10 },
                { text: "速度", max: 10 },
                { text: "活跃", max: 10 },
                { text: "合作", max: 10 },
                { text: "技能", max: 10 },
                { text: "评价", max: 10 },
              ],
              center: ["50%", "50%"],
              radius: widthios,
              name: {
                textStyle: {
                  fontSize: 14,
                  color: "#000",
                },
              },
              splitNumber: 3,
              // shape: "circle",
              shape: "polygon",
              axisLine: {
                lineStyle: {
                  color: "rgb(64, 158, 255)", //射线的颜色
                },
              },
              splitArea: {
                show: true,
                areaStyle: {
                  color: "#fff", // 图表背景的颜色
                },
              },
              splitLine: {
                show: true,
                lineStyle: {
                  width: 1,
                  color: "rgb(64, 158, 255)", // 设置网格的颜色
                },
              },
            },
            series: [
              {
                type: "radar",
                tooltip: {
                  trigger: "item",
                },
                color: "rgb(64, 158, 255)",
                itemStyle: { normal: { areaStyle: { type: "default" } } },
                data: [
                  {
                    value: [
                      user.res_exper,
                      user.res_speed,
                      user.res_active,
                      user.res_cooper,
                      user.res_skill,
                      user.res_evaluate,
                    ],
                    name: "个人资料图",
                    name: "个人资料图",
                  },
                ],
              },
            ],
          });
        }
      } else {
        // console.log("请求失败");
      }
    });
  },
  methods: {
    handleClick(event) {
      var index = Number(event.index) + 1;
      this.diluli(index);
    },
    diluli(index) {
      var that = this;
      that.rencaiulboxli_rightli = [];
      this.$axios({
        url: "/api/getTalentTab",
        method: "post",
        data: {
          userid: that.id,
          page: "1",
          limit: that.limit,
          tab_id: index,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          that.lizero = false;
          that.rencaiulboxli_rightli = res.data.result.list;
        } else {
          that.lizero = true;
        }
      });
    },
    guanzhu(id) {
      var that = this;
      if (that.userinfo != null) {
        if (that.gz == 0) {
          this.$axios({
            headers: { "X-CSRF-TOKEN": that.userinfo.token },
            url: "/api/getFollow",
            method: "post",
            data: {
              userid: that.userinfo.userid,
              member_id: id,
            },
          }).then((res) => {
            if (res.data.code == 1) {
              this.$message({
                message: "关注成功",
                duration: 1000,
              });
              that.gz = 1;
            } else {
              this.$message({
                message: res.data.messages,
                duration: 1000,
              });
            }
          });
        } else {
          this.$axios({
            headers: { "X-CSRF-TOKEN": that.userinfo.token },
            url: "/api/getCancelFollow",
            method: "post",
            data: {
              userid: that.userinfo.userid,
              member_id: id,
            },
          }).then((res) => {
            if (res.data.code == 1) {
              this.$message({
                message: "取消关注成功",
                duration: 1000,
              });
              that.gz = 0;
            } else {
              this.$message({
                message: res.data.messages,
                duration: 1000,
              });
            }
          });
        }
      } else {
        this.$router.push({
          path: "/sjlogin",
        });
      }
    },
    more(id) {
      if (id == 1) {
        this.more1 = !this.more1;
        this.more2 = false;
        this.more3 = false;
      } else if (id == 2) {
        this.more2 = !this.more2;
        this.more1 = false;
        this.more3 = false;
      } else if (id == 3) {
        this.more3 = !this.more3;
        this.more2 = false;
        this.more1 = false;
      }
    },
    ck_jl(id) {
      this.$router.push({ path: "/Rencai_jl", query: { data: id } });
    },
    photo_list(id) {
      this.$router.push({ path: "/Chengguo_c", query: { data: id } });
    },
    fabulink(id, username) {
      this.$router.push({ path: "/Sjfabu", query: { data: id, un: username } });
    },
  },
  created() {
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
    this.tokentimeyz();
    var that = this;
    var id = that.$route.query.data;
    that.id = id;
    this.$axios({
      url: "/api/getTalentDetails",
      method: "post",
      data: {
        userid: id,
      },
    }).then((res) => {
      if (res.data.code == 1) {
        that.user = res.data.result;
        document.title = res.data.result.username + " 主页";
        this.fenxiang(
          document.title,
          res.data.result.username,
          res.data.result.userpic
        );
        if (that.user.cover_img == null) {
          that.user.cover_img = "0";
        }
        if (that.user.userpic == null || that.user.userpic == "") {
          that.user.userpic =
            "https://task.hozoin.cn/Works/20210402/sj-0768621535.png";
        }
        that.userskill = res.data.result.skill.split(",");
        console.log(that.userskill)
        that.skillone = that.userskill[0];
        if (that.userinfo != null) {
          if (that.userinfo.userid == res.data.result.userid) {
            that.guanzhutype = false;
          } else {
            that.guanzhutype = true;
          }
        } else {
          that.guanzhutype = true;
        }
        that
          .$axios({
            url: "/api/getFollowFans",
            method: "post",
            data: {
              userid: that.userinfo.userid,
              f_type: "1",
              page: "1",
              limit: "100",
            },
          })
          .then((res) => {
            if (res.data.code == 1) {
              for (var i = 0; i < res.data.result.list.length; i++) {
                if (res.data.result.list[i].userid == id) {
                  that.gz = "1";
                  return false;
                }
              }
            } else {
              // console.log("请求失败");
            }
          });
      } else {
        // console.log("请求失败");
      }
    });
    that
      .$axios({
        url: "/api/getFollowFans",
        method: "post",
        data: {
          userid: id,
          f_type: "2",
          page: "1",
          limit: "10000",
        },
      })
      .then((res) => {
        // console.log(res);
        if (res.data.code == 1) {
          that.fsnum = res.data.result.count;
        } else {
          // console.log("请求失败");
        }
      });
    this.diluli(1);
  },
};
</script>
<style>
@import "../assets/css/rencai.css";
</style>
